import { postRequest, getRequest, deleteRequest } from '../utils/request'


export default {

    //获取code验证吗
    getCaptCha() {
        return getRequest('/getcaptcha')
    },
    //登录
    login(params) {
        return postRequest(`/login`, params)
    },
    dd_auth(authCode) {
        return getRequest(`/auth?authCode=${authCode}`)
    },
    //退出系统
    logout() {
        return postRequest("/logout")
    },
    //获取用户信息
    userinfo() {
        return getRequest('/sys/userInfo')
    },
    updatePass(params) {
        return postRequest('/sys/user/updatePass', params)
    },
    verifyUsername(userName) {
        return getRequest(`/sys/user/verifyUsername`, userName)
    },
    //获取menu信息
    getMenus() {
        return getRequest('/sys/menu/nav')
    },
    //查询人员列表
    list(params) {
        const { params: para } = params
        return getRequest('/sys/user/list', para)
    },
    //获取菜单列表
    menuList() {
        return getRequest('/sys/menu/list')
    },
    //增加或者修改菜单
    changeMenus(status, params) {
        return postRequest(`/sys/menu/${status}`, params)
    },
    //查询单个信息
    getOneMenuInfo(id) {
        return getRequest(`/sys/menu/info/${id}`)
    },
    //删除菜单信息
    deleteMenuInfo(id) {
        return deleteRequest(`/sys/menu/delete/${id}`)
    },
    /**
     * 角色管理的接口获取
     */
    roleList(params) {
        return getRequest('/sys/role/list', params)
    },
    listRole(alIdName, meIdName, soeIdName) {
        return getRequest(`/sys/role/listRole?alIdName=${alIdName}&meIdName=${meIdName}&soeIdName=${soeIdName}`)
    },
    //修改或增加role信息
    changeRoles(status, params) {
        return postRequest(`/sys/role/${status}`, params)
    },
    //获取单个role信息
    getOneRoleInfo(id) {
        return getRequest(`/sys/role/info/${id}`)
    },
    //也是获取单个role信息
    getRoleInfo(id) {
        return getRequest(`/sys/role/oneinfo/${id}`)
    },
    //删除role
    deleteRoleInfo(ids) {
        return deleteRequest(`/sys/role/delete`, ids)
    },
    //修改权限
    updatePerm(id, parma) {
        return postRequest(`/sys/role/perm/${id}`, parma)
    },
    //获取一个人员信息
    getOneUserInfo(id) {
        return getRequest(`/sys/user/info/${id}`)
    },
    //修改人员信息
    updateUsers(status, params, meId, soeId) {
        return postRequest(`/sys/user/${status}?meId=${meId==undefined?"":meId}&soeId=${soeId==undefined?"":soeId}`, params)
    },
    //删除人员信息
    deleteSysUser(ids, username) {
        return deleteRequest(`/sys/user/delete?username=${username}`, ids)
    },
    saveOrUpdates(id, params) {
        return postRequest(`/sys/user/role/${id}`, params)
    },
    saveOrUpdateDepart(status, params, value) {
        return postRequest(`/sys/depart/${status}?value=${value}`, params)
    },
    getQueryDepart(dName) {
        return postRequest(`/sys/depart/list?dName=${dName}`)
    },
    queryById(id) {
        return postRequest(`/sys/depart/queryById?id=${id}`)
    },
    getDeleteById(id) {
        return postRequest(`/sys/depart/delete?id=${id}`)
    },
    updateByUserId(id, depaertId) {
        return postRequest(`/sys/user/updateByUserId?depaertId=${depaertId}`, id)
    },
    repass(id) {
        return postRequest(`/sys/user/repass?userId=${id}`)
    },
    getDictList(params) {
        return getRequest('/api/dict/list', params)
    },
    saveDict(status, params) {
        return postRequest(`/api/dict/${status}`, params)
    },
    queryDictById(id) {
        return postRequest(`/api/dict/queryDictById?id=${id}`)
    },
    dictDelete(id) {
        return postRequest(`/api/dict/dictDelete?id=${id}`)
    },
    activateTempInfo(activate_ticket, card_id, encrypt_code, outer_str, openid) {
        return getRequest(`/api/test/activatetempinfo?activate_ticket=${activate_ticket}&&card_id=${card_id}&&encrypt_code=${encrypt_code}&&outer_str=${outer_str}&&openid=${openid}`)
    },
    //删除公众号
    deleteWeChat(wxId) {
        return deleteRequest(`/sy/wechatbasic/delete`, wxId)
    },

    saveWeChat(status, params) {
        return postRequest(`/sy/wechatbasic/${status}`, params)
    },
    selectWeChatHx(wxId) {
        return getRequest(`/sy/wechatbasic/selectWeChat`, wxId)
    },
    selectMerHxi(mgId) {
        return getRequest(`/sy/merChan/selectMerFz`, mgId)
    },
    /**
     * 获取微信相关信息
     * @returns
     */
    wxinfos(params) {
        const { params: para } = params
        return getRequest('/api/test/getwxinfos',para)
    },
    /**
     * 树的选中事件
     * @param {*} typeid
     * @param {*} checkid
     * @returns
     */
    checkinfos(typeid,checkid){
        return getRequest(`/sys/user/checkinfos/${typeid}/${checkid}`)
    },
    queryByMeid(){
        return getRequest(`/sys/user/queryByMeid`)
    },
    /**
     * 获取商盟级联
     */
    getlistAlliance(){
       return getRequest(`/sy/wechatbasic/getlistAlliance`) 
    },
    /**
     * 公众号列表
     */
    WXlist(params) {
        const { params: para } = params
        return getRequest('/sy/wechatbasic/list', para)
    },
    /**
     * 获取省列表
     */
    ProvTree(){
        return getRequest('/sys/region/getListProv')

    },
    //获取市区
    getListCity(regionCode,regionId){
        if(regionCode === undefined){
            regionCode = "110000"
        }
        if(regionId === undefined){
            regionId = '';
        }
        return getRequest(`/sys/region/getListCity?regionCodes=${regionCode}&regionId=${regionId}`)
    },
    //
    //删除省市区
    delete(regionId) {
        return deleteRequest(`/sys/region/delete/${regionId}`)
    },
    //查询省市区
    queryprvoById(regionId) {
        return postRequest(`/sys/region/queryById?regionId=${regionId}`)
    },
    saveProv(status,params) {
        return postRequest(`/sys/region/${status}`, params)
    },
   //日志列表
   SysList(username,solModule,solPath,solType,startDate,endDate,solMethod,size,current){
    if(username === undefined){
        username =''
    }
    if(solModule === undefined){
        solModule =''
    }
    if(solPath === undefined){
        solPath =''
    }
    if(solType === undefined){
        solType =''
    }
    if(solMethod === undefined){
        solMethod =''
    }
    if(startDate === undefined){
        startDate = ''
    }
    if(endDate === undefined){
        endDate = ''
    }
        return getRequest(`/sys/operalogs/list?username=${username}&solModule=${solModule}&solPath=${solPath}&solType=${solType}&startDate=${startDate}&endDate=${endDate}&solMethod=${solMethod}&size=${size}&current=${current}`)    
   },                                 
   //删除日志
   deletes(solId){
    return deleteRequest(`/sys/operalogs/delete?solId=${solId}`)
   },
   //查询日志
   querySysById(solId) {
    return postRequest(`/sys/operalogs/queryById?solId=${solId}`)
},



  


}
