<template>
  <div id="app">
    <!-- <keep-alive> -->
      <router-view />
    <!-- </keep-alive> -->
  </div>
</template>

<script>
export default {
  name: "App",
  watch: {
    $route(to, from) {
      if (to.path != "/login") {
        let obj = {
          name: to.name,
          title: to.meta.title,
          query: JSON.stringify(to.query)
        };
        this.$store.commit("addTab", obj);
      }
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  font-size: 16px;
}
</style>
