import axios from 'axios'
import Element from 'element-ui'
// let baseURL = 'http://192.168.2.232:8091/qrcode-boot'
// let baseURL = 'http://www.siruinfo.com/qrcode-boot'
let baseURL = 'http://shang.xiaoruizhixi.com/qrcode-boot'
// 创建axios实例
//axios.defaults.baseURL = baseURL;// api 的 base_url

axios.defaults.timeout = 1000*60*3 // 请求超时时间

axios.defaults.headers = { 'Content-Type': 'application/json;charset=utf-8' }
// request 请求拦截器
axios.interceptors.request.use(
  config => {
    //将token判断添加至请求头
    if (localStorage.getItem("shangytoken")) {
      config.headers['Authorization'] = localStorage.getItem("shangytoken")
    }
    return config
  },
  error => {
    // Do something with request error
    Promise.reject(error)
  }
)

// response 响应拦截器18653693035
axios.interceptors.response.use(
  response => {
    let res = response.data
    if (res.code === 200) {
      return response
    } else {
      Element.Message.error(res.message)
      return Promise.reject(response.data.msg)
    }
  },
  error => {

    if (error.response.data) {
      if(error.response.data.msg) {
        error.massage = error.response.data.msg
      } else {
        error.massage = '系统异常！'
      }
    }

    if (error.response.status === 401) {
      Element.Message.error("登录超时，请重新登录！");
      window.open("/login", "_self");
      return;
    }
    Element.Message.error(error.massage, { duration: 3000 })
    return Promise.reject(error)
  }
)
export const httpRequest = (url,params,method) => {
  return axios({
    method: method,
    url: `${baseURL}${url}`,
    data: params
  })
}
export const postRequest = (url, params) => {
  return axios({
    method: 'post',
    url: `${baseURL}${url}`,
    data: params
  })
}
export const putRequest = (url, params) => {
  return axios({
    method: 'put',
    url: `${baseURL}${url}`,
    data: params
  })
}
export const deleteRequest = (url, params) => {
  return axios({
    method: 'delete',
    url: `${baseURL}${url}`,
    data: params
  })
}
export const getRequest = (url, params) => {
  return axios({
    method: 'get',
    url: `${baseURL}${url}`,
    params: params
  })
}
/**
 * 下载文件
 * @param url 文件路径
 * @param fileName 文件名
 * @param parameter
 * @returns {*}
 */
export function downloadFile(url, fileName, parameter) {
  return downFile(url, parameter).then((data) => {
    alert("dfasdfasdfsadfasdfasdfa")
    if (!data || data.size === 0) {
      Vue.prototype['$message'].warning('文件下载失败')
      return
    }
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(new Blob([data]), fileName)
    } else {
      let url = window.URL.createObjectURL(new Blob([data]))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link) //下载完成移除元素
      window.URL.revokeObjectURL(url) //释放掉blob对象
    }
  })
}
/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
/*export const downFile = (url, params) => {
  return axios({
    method: 'get',
    url: `${baseURL}${url}`,
    params: params,
    responseType: 'blob'
  })
}*/

export function downFile(url,parameter){
  return axios({
    url: `${baseURL}${url}`,
    params: parameter,
    method:'get' ,
    responseType: 'blob'
  })
}
