<template>
  <div>
    <dv-decoration-10 style="width: 100%; height: 5px" />
    <div class="top">
      <div class="top1">
        <div class="span_text">
          <span><i class="el-icon-edit-outline"></i>开卡总数</span>
          <span class="top_num"
            ><span class="num">{{ mainsInfo.count }}</span
            ><i>张</i></span
          >
        </div>
        <div class="span_text">
          <span><i class="el-icon-folder"></i>上月开卡量</span>
          <span class="top_num"
            ><span class="num">{{ mainsInfo.lastMontNum }}</span
            ><i>张</i></span
          >
        </div>
        <div class="span_text">
          <span><i class="el-icon-tickets"></i>今日开卡量</span>
          <span class="top_num"
            ><span class="num">{{ mainsInfo.todayNum }}</span
            ><i>张</i></span
          >
        </div>
        <div class="span_text">
          <span><i class="el-icon-document"></i>昨日开卡量</span>
          <span class="top_num"
            ><span class="num">{{ mainsInfo.yestNum }}</span
            ><i>张</i></span
          >
        </div>
        <div class="span_text">
          <span><i class="el-icon-document-checked"></i>门店数量</span>
          <span class="top_num"
            ><span class="num">{{ mainsInfo.storeNum }}</span
            ><i>个</i></span
          >
        </div>
        <div class="span_text">
          <span><i class="el-icon-document-copy"></i>标签数量</span>
          <span class="top_num"
            ><span class="num">{{ mainsInfo.lableNum }}</span
            ><i>个</i></span
          >
        </div>
      </div>

      <div class="top2" v-show="level == 2 || level == 3">
        <div class="span_text">
          <span><i class="el-icon-notebook-2"></i>微信粉丝总数</span>
          <span class="top_num"
            ><span class="num">{{ mainsInfo.publicPersons }}</span
            ><i>人</i></span
          >
        </div>
        <div class="span_text">
          <span><i class="el-icon-notebook-1"></i>开卡已关注</span>
          <span class="top_num"
            ><span class="num">{{ mainsInfo.openPubNum }}</span
            ><i>人</i></span
          >
        </div>
        <div class="span_text">
          <span><i class="el-icon-collection"></i>开卡未关注</span>
          <span class="top_num"
            ><span class="num">{{ mainsInfo.openPubNoNum }}</span
            ><i>人</i></span
          >
        </div>
        <div class="span_text">
          <span><i class="el-icon-moon-night"></i>睡眠会员</span>
          <span class="top_num"
            ><span class="num">{{ mainsInfo.sleepNum }}</span
            ><i>人</i></span
          >
        </div>
        <div class="span_text">
          <span><i class="el-icon-partly-cloudy"></i>低频会员</span>
          <span class="top_num"
            ><span class="num">{{ mainsInfo.lowFreNum }}</span
            ><i>人</i></span
          >
        </div>
        <div class="span_text">
          <span><i class="el-icon-sunny"></i>高频会员</span>
          <span class="top_num"
            ><span class="num">{{ mainsInfo.highFreNum }}</span
            ><i>人</i></span
          >
        </div>
      </div>
    </div>

    <dv-decoration-10 style="width: 100%; height: 5px" />
    <div class="charts">
      <div class="first">
        <dv-border-box-11 title="开卡门店TOP10">
          <div class="chr">
            <el-button-group class="chrage_top">
              <el-button
                size="small"
                type="primary"
                @click="changeDay('TODAY')"
                :plain="selectA"
                >今日</el-button
              >
              <el-button
                size="small"
                type="primary"
                @click="changeDay('YESTDAY')"
                :plain="selectB"
                >昨日</el-button
              >
              <el-button
                size="small"
                type="primary"
                @click="changeDay('WEEK')"
                :plain="selectC"
                >本周</el-button
              >
              <el-button
                size="small"
                type="primary"
                @click="changeDay('LASTWEEK')"
                :plain="selectD"
                >上周</el-button
              >
              <el-button
                size="small"
                type="primary"
                @click="changeDay('MONTH')"
                :plain="selectE"
                >本月</el-button
              >
              <el-button
                size="small"
                type="primary"
                @click="changeDay('LASTMONTH')"
                :plain="selectF"
                >上月</el-button
              >
              <el-button
                size="small"
                type="primary"
                @click="changeDay('180DAY')"
                :plain="selectH"
                >180天</el-button
              >
              <el-button
                size="small"
                type="primary"
                @click="changeDay('YEAR')"
                :plain="selectI"
                >今年</el-button
              >
            </el-button-group>
            <div class="chrage_bot" id="myChart1"></div>
          </div>
        </dv-border-box-11>

        <dv-border-box-11 title="关注 / 开卡占比">
          <div class="chr">
            <span class="selectMD" v-show="level === 2">选择门店:</span>
            <el-select
              v-model="value"
              @change="selectChange"
              placeholder="选择门店统计"
              class="chrage_top1"
              v-show="level === 2"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <div class="chrage_bot1" id="myChart4"></div>
          </div>
        </dv-border-box-11>
      </div>
      <dv-decoration-10 :style="mystyle1" />
      <div class="first" :style="mystyle2">
        <dv-border-box-11 title="消息触达对比">
          <div class="chr" id="myChart6"></div>
        </dv-border-box-11>
        <dv-border-box-11 title="用户开卡趋势">
          <div class="chr">
            <span class="selectDAY">选择日期:</span>
            <el-date-picker
              v-model="value2"
              class="chrage_top2"
              type="daterange"
              align="right"
              @change="changeDate"
              unlink-panels
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <div class="chrage_bot" id="myChart3"></div>
          </div>
        </dv-border-box-11>
      </div>
    </div>
  </div>
</template>

<script>
import user from "../api/user";
import mains from "../api/maininfo";
import store from "../api/stroe";
export default {
  name: "Index",
  data() {
    return {
      level: "",
      mainsInfo: {},
      allOpenNum: 28835,
      openPublicNum: 5211,
      mystyle1: { width: "100%", height: "5px" },
      mystyle2: { "margin-top": "20px" },
      mystyle3: {},
      stat: "YESTDAY",

      options: [],
      value: 0,

      value2: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      selectA: true,
      selectB: false,
      selectC: true,
      selectD: true,
      selectE: true,
      selectF: true,
      selectH: true,
      selectI: true,

      startDate: "",
      endDate: "",
    };
  },
  created() {
    store.listStores().then((res) => {
      if (res.data.data.result !== "受限权限") {
        this.options = res.data.data.result;
      }
    });
    const end = new Date();
    end.setTime(end.getTime());

    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7); //七天前的时间

    this.value2[0] = this.dateFormat(start);
    this.value2[1] = this.dateFormat(end);
  },
  mounted() {
    user.userinfo().then((res) => {
      this.level = res.data.data.result.ranks;
      if (this.level == 0 || this.level == 1) {
        this.mystyle1.display = "none";
        this.mystyle2.display = "none";
      }
      this.getMainInfos();
      this.drawLine1();
      this.drawLine4();
      if (this.level == 2 || this.level == 3) {
        this.drawLine3();
        this.drawLine6();
      }
    });
  },
  methods: {
    dateFormat(dateData) {
      var date = new Date(dateData);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
    getMainInfos() {
      mains.mains().then((res) => {
        this.mainsInfo = res.data.data.result;
      });
    },
    changeDay(days) {
      this.stat = days;
      if (days === "TODAY") {
        this.selectA = false;
        this.selectB = true;
        this.selectC = true;
        this.selectD = true;
        this.selectE = true;
        this.selectF = true;
        this.selectH = true;
        this.selectI = true;
      } else if (days === "YESTDAY") {
        this.selectA = true;
        this.selectB = false;
        this.selectC = true;
        this.selectD = true;
        this.selectE = true;
        this.selectF = true;
        this.selectH = true;
        this.selectI = true;
      } else if (days === "WEEK") {
        this.selectA = true;
        this.selectB = true;
        this.selectC = false;
        this.selectD = true;
        this.selectE = true;
        this.selectF = true;
        this.selectH = true;
        this.selectI = true;
      } else if (days === "LASTWEEK") {
        this.selectA = true;
        this.selectB = true;
        this.selectC = true;
        this.selectD = false;
        this.selectE = true;
        this.selectF = true;
        this.selectH = true;
        this.selectI = true;
      } else if (days === "MONTH") {
        this.selectA = true;
        this.selectB = true;
        this.selectC = true;
        this.selectD = true;
        this.selectE = false;
        this.selectF = true;
        this.selectH = true;
        this.selectI = true;
      } else if (days === "LASTMONTH") {
        this.selectA = true;
        this.selectB = true;
        this.selectC = true;
        this.selectD = true;
        this.selectE = true;
        this.selectF = false;
        this.selectH = true;
        this.selectI = true;
      } else if (days === "180DAY") {
        this.selectA = true;
        this.selectB = true;
        this.selectC = true;
        this.selectD = true;
        this.selectE = true;
        this.selectF = true;
        this.selectH = false;
        this.selectI = true;
      } else if (days === "YEAR") {
        this.selectA = true;
        this.selectB = true;
        this.selectC = true;
        this.selectD = true;
        this.selectE = true;
        this.selectF = true;
        this.selectH = true;
        this.selectI = false;
      }
      this.drawLine1();
    },
    drawLine1() {
      let myChart1 = this.$echarts.init(document.getElementById("myChart1"));
      mains.storeOpenTop10(this.stat).then((res) => {
        myChart1.setOption({
          tooltip: {},
          xAxis: {
            data: res.data.data.result.storeName,
            axisLabel: {
              //轴文字标签
              show: true,
            },
            axisLabel: {
              interval: 0,
              rotate: -15,
              padding: [10, 0, 0, -5],
              textStyle: {
                color: "#75bedc",
                fontSize: "12", //文字大小
              },
            },
          },
          color: ["#ee6666"],
          yAxis: {},
          series: [
            {
              name: "数量",
              type: "bar",
              data: res.data.data.result.openNum,
            },
          ],
        });
      });
    },

    drawLine3() {
      let myChart3 = this.$echarts.init(document.getElementById("myChart3"));
      mains.getVipOpenDate(this.value2).then((res) => {
        myChart3.setOption({
          // title: { text: "客户关注走势图" },
          tooltip: {},
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: res.data.data.result.openDate,
          },
          color: ["#75bedc"],
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: res.data.data.result.num,
              type: "line",
              areaStyle: {},
            },
          ],
        });
      });
    },
    drawLine4() {
      let myChart4 = this.$echarts.init(document.getElementById("myChart4"));
      mains.pubpercent(this.value).then((res) => {
        myChart4.setOption({
          tooltip: {
            trigger: "item",
          },
          title: {
            x: "45%", //X坐标
            y: "45%", //Y坐标
            text: "总开卡：", //主标题
            subtext: Number(res.data.data.result.count),
            textStyle: {
              //标题样式
              fontSize: 20,
              fontWeight: "bolder",
              color: "#aaa",
              transform: "translate(-50%,-50%)",
            },
            subtextStyle: {
              //副标题样式
              fontSize: 26,
              fontWeight: "bolder",
              color: "#333",
              formatter: "",
              transfrom: "translate(-50%,-50%)",
            },
          },
          legend: {
            bottom: "8%",
            left: "right",
          },
          color: ["#ee6666", "#5470c6"],
          series: [
            {
              name: "开卡占比",
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: true,
                  formatter: "{b}: {c}({d}%)", //自定义显示格式(b:name, c:value, d:百分比)
                },
              },
              itemStyle: {
                borderRadius: 10,
                borderColor: "#fff",
                borderWidth: 2,
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 16,
                  fontWeight: "bold",
                },
              },

              data: [
                { value: res.data.data.result.openPubNum, name: "开卡关注" },
                {
                  value:
                    Number(res.data.data.result.count) -
                    Number(res.data.data.result.openPubNum),
                  name: "开卡未关注",
                },
              ],
            },
          ],
        });
      });
    },
    drawLine6() {
      let myChart6 = this.$echarts.init(document.getElementById("myChart6"));
      mains.getInfoSucc().then((res) => {
        myChart6.setOption({
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },

          legend: {
            bottom: "5%",
            data: ["触达数量", "发送数量", "发送成功"],
          },
          color: ["#fac858", "#3ba272", "#ea7ccc"],
          xAxis: [
            {
              type: "category",
              data: res.data.data.result.sendDate==0?this._init7Days():res.data.data.result.sendDate,
              axisPointer: {
                type: "shadow",
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              min: 0,
              axisLabel: {
                formatter: "{value} ",
              },
            },
          ],
          series: [
            {
              name: "触达数量",
              type: "bar",
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                },
              },
              data: res.data.data.result.countNums.length===0?[0,0,0,0,0,0,0,0]: res.data.data.result.countNums
            },
            {
              name: "发送数量",
              type: "bar",
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                },
              },
              data: res.data.data.result.allNum.length===0?[0,0,0,0,0,0,0,0]: res.data.data.result.allNum
            },
            {
              name: "发送成功",
              type: "bar",
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                },
              },
              data: res.data.data.result.succNum.length===0?[0,0,0,0,0,0,0,0]: res.data.data.result.succNum
            },
          ],
        });
      });
    },
    selectChange(value) {
      this.value = value;
      this.drawLine4();
    },
    changeDate(value) {
      this.value2 = value;
      this.drawLine3();
    },
    _init7Days(){
      const end = new Date();
      end.setTime(end.getTime());

      const start7 = new Date();
      start7.setTime(start7.getTime() - 3600 * 1000 * 24 * 7); //7天前的时间

      const start6 = new Date();
      start6.setTime(start6.getTime() - 3600 * 1000 * 24 * 6); //6天前的时间

      const start5 = new Date();
      start5.setTime(start5.getTime() - 3600 * 1000 * 24 * 5); //5天前的时间

      const start4 = new Date();
      start4.setTime(start4.getTime() - 3600 * 1000 * 24 * 4); //4天前的时间

      const start3 = new Date();
      start3.setTime(start3.getTime() - 3600 * 1000 * 24 * 3); //3天前的时间

      const start2 = new Date();
      start2.setTime(start2.getTime() - 3600 * 1000 * 24 * 2); //2天前的时间

      const start1 = new Date();
      start1.setTime(start1.getTime() - 3600 * 1000 * 24 * 1); //1天前的时间
      
      let _7days=[this.dateFormat(start7),this.dateFormat(start6),this.dateFormat(start5),
      this.dateFormat(start4),this.dateFormat(start3),this.dateFormat(start2),this.dateFormat(start1),this.dateFormat(end)]

      console.log(_7days)
      return _7days
    }
  },
};
</script>

<style lang="scss" scoped>
.top {
  margin-bottom: 20px;
  .top1,
  .top2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 15px;
    .span_text {
      height: 110px;
      width: 230px;
      // background-color: rgb(238, 107, 31);
      background-color: #409eff;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      border-radius: 5px;
      margin-left: 15px;
      transform: skewX(-5deg);
      transition: all 0.4s ease-in-out;
      box-shadow: 1px 1px 3px 1px gray;
      &:hover {
        box-shadow: 1px 1px 5px 5px gray;
      }
      span {
        display: block;
        font-size: 18px;
        color: aliceblue;
      }
      .top_num {
        display: flex;
        flex-direction: row;
        .num {
          font-size: 26px;
        }
        i {
          font-size: 14px;
          margin-left: 10px;
        }
      }
    }
  }
}
.charts {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .first {
    width: 100%;
    height: 500px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .chr {
      height: 95%;
      margin-right: 10px;
      width: 95%;
      margin-top: 20px;
      margin-left: 15px;
      position: relative;
      .chrage_top {
        position: absolute;
        top: 45px;
        right: 80px;
      }
      .selectMD {
        position: absolute;
        top: 65px;
        right: 370px;
        font-weight: bold;
        font-style: oblique;
      }
      .chrage_top1 {
        position: absolute;
        width: 330px;
        top: 45px;
        right: 30px;
        z-index: 999;
      }
      .selectDAY {
        position: absolute;
        top: 65px;
        right: 430px;
        font-weight: bold;
        font-style: oblique;
      }
      .chrage_top2 {
        position: absolute;
        width: 380px;
        top: 45px;
        right: 30px;
      }
      .chrage_bot {
        height: 80%;
        width: 100%;
        position: absolute;
        top: 80px;
      }
      .chrage_bot1 {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 20px;
      }
    }
  }
}
</style>
