<template>
  <div class="menu">
    <el-menu
      :default-active="this.$store.state.menus.editableTabsValue"
      class="el-menu-vertical-demo"
      background-color="#334154"
      text-color="#fff"
      unique-opened
    >
      <router-link to="/index">
        <el-menu-item
          class="dashboard"
          index="Index"
          @click="selectMenu({name: 'Index', title: '首页'})"
        >
          <i class="el-icon-s-home"></i>
          <span slot="title">首页</span>
        </el-menu-item>
      </router-link>

      <el-submenu :index="menu.name"  v-for="(menu,index) in menuList" :key="index">
        <template slot="title">
          <i :class="menu.icon"></i>
          <span slot="title">{{menu.title}}</span>
        </template>
        <router-link :to="item.path" v-for="(item,i) in menu.children" :key="i">
          <el-menu-item :index="item.name" v-show="item.isShow=='Y'" @click="selectMenu(item)">
            <i :class="item.icon"></i>
            <span slot="title">{{item.title}}</span>
          </el-menu-item>
        </router-link>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "SideMenu",
  data() {
    return {};
  },
  computed: {
    menuList() {
      return this.$store.state.menus.menuList;
    }
  },
  methods: {
    selectMenu(item) {
      this.$store.commit("addTab", item);
    }
  }
};
</script>

<style scoped lang="scss">
.menu {
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(51, 65, 84, 1);
}
.el-menu-vertical-demo {
  background-color: steelblue;
  height: 100%;
  border-right: none !important;
}
.el-submenu {
  margin: 0;
  padding: 0;
  .el-menu {
    .el-menu-item {
      background-color: rgb(36, 33, 33) !important;
      &:hover {
        background-color: rgb(67, 74, 80) !important;
      }
    }
  }
}
</style>