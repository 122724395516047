<template>
  <el-row class="navbar">
    <el-col :span="8">
      <div class="grid-content bg-purple bg-purple-left">
        <span class="logo">
          <el-image class="elimage" :src="logoUrl" fit="cover"></el-image>
        </span>
        <span class="logo_text">睿达物联网</span>
      </div>
    </el-col>
    <el-col :span="8">
      <div class="grid-content bg-purple-light">
        <strong>商盈管理系统</strong>
      </div>
    </el-col>
    <el-col :span="8">
      <div class="grid-content bg-purple bg-purple-right">
        <el-link type="primary" style="color: #409eff; font-size: 16px; margin-right: 15px">
          <i class="el-icon-question"></i
          ><span type="warning" style="color: #409eff; font-size: 16px" @click="helpClick"
            >帮助中心</span
          >
        </el-link>
        <div>
          <span class="el-dropdown-link">
            当前级别：
            <strong>{{ level }}</strong>
          </span>
          &nbsp;
          <el-dropdown class="down">
            <span class="el-dropdown-link">
              欢迎您：
              <strong>{{ userInfo.username }}</strong>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <router-link to="/usercenter">
                <el-dropdown-item class="usercenter">个人中心</el-dropdown-item>
              </router-link>
              <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import api from "../api/user";

export default {
  name: "AppHeader",
  data() {
    return {
      userInfo: {
        id: "",
        username: "",
        avatar: "",
      },
      level: "",
      logoUrl: require("../assets/xr.png"),
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      api.userinfo().then((res) => {
        if (res.data.data.result.soeId) {
          this.level = "门店";
        } else if (res.data.data.result.meId) {
          this.level = "商户";
        } else if (res.data.data.result.alId) {
          this.level = "商盟";
        } else {
          this.level = "平台";
        }
        this.userInfo = res.data.data.result;
      });
    },
    logout() {
      api.logout().then((res) => {
        localStorage.clear();
        sessionStorage.clear();

        this.$store.commit("resetState");

        this.$router.replace("/login");
      });
    },
    helpClick() {
      //window.open("/pdfjs/web/viewer3.html?file="+encodeURIComponent("http://shang.xiaoruizhixi.com/qrcode-boot/vipCard/operationManual.pdf"));
      window.open("/pdf/web/viewer.html");
    },
  },
};
</script>

<style scoped lang="scss">
.bg-purple-left {
  text-align: left;
  height: 50px;
  width: 225px;
  background-color: rgba(51, 65, 84, 1);
  .logo {
    width: 225px;
    position: absolute;
    background-color: rgba(51, 65, 84, 1);
    height: 49px;
    line-height: 49px;
    border-bottom: 1px solid #4b4242;
    left: 0px;
    .elimage {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 20px;
      top: 13px;
      border-radius: 50%;
    }
  }
   .logo_text {
    position: absolute;
    left: 60px;
    color: #409eff;
    font-size: 20px;
    font-weight: bold;
    line-height: 50px;
    top:2px
  }
}
.bg-purple-left i {
  line-height: 50px;
  font-size: 34px;
}
.bg-purple-right {
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.bg-purple-light {
  strong {
    color: #409eff;
    font-weight: bold;
    font-size: 20px;
  }
}
.avatar {
  height: 40px;
  padding-top: 10px;
  margin-right: 10px;
}
.el-avatar {
  width: 30px;
  height: 30px;
}
.down {
  cursor: pointer;
}
.usercenter {
  text-decoration: none;
}
.el-dropdown-link {
  color: #409eff;
}
</style>
