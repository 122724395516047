import { postRequest, getRequest, deleteRequest } from '../utils/request'


export default {

   /**
    * 获得色块数据
    * @returns
    */
    mains() {
        return getRequest(`/api/main/infos`)
    },
    /**
     * 门店开卡top10
     * @param {*} stat 时间范围 
     * @returns 
     */
    storeOpenTop10(stat){
        return getRequest(`/api/main/storeopen/${stat}`)
    },
    /**
     * 关注开卡占比
     */
    pubpercent(soeId){
        return getRequest(`/api/main/pubpercent/${soeId}`)
    },
    /**
     * 
     * @returns 用户卡开趋势 按照日期 七天趋势
     */
    getVipOpenDate(value){
        return getRequest(`/api/main/openvip/${value[0]}/${value[1]}`)
    },
    /**
     * 信息触达占比
     * @returns 
     */
    getInfoSucc(){
        return getRequest(`/api/main/sendInfoSuccess`)
    }
}
