// 创建应用程序的路由器
import Vue from 'vue'
import VueRouter from 'vue-router'
// 此时就可以在Vue实例中配置路由器了
Vue.use(VueRouter)

// 引入组件
import Home from '../views/Home.vue'
import Index from '../views/Index.vue'
import Center from '../views/UserCenter.vue'

import store from '../store'
import userApi from '../api/user'
// 创建并暴露一个路由器

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [

	{
		path: "/",
		name: 'Home',
		component: Home,
		redirect:'/index',
		children: [
			{
				path: "/index",
				name: 'Index',
				meta: {
					title: '首页'
				},
				component: Index
			},
			{
				path: "/usercenter",
				name: 'Center',
				meta: {
					title: '个人中心'
				},
				component: Center
			}
		]
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/Login.vue')
	},
	{
		path: '/opencard*',
		name: 'OpenCard',
		component: () => import('@/views/open/Opencard.vue')
	},
	{
		path: '/autho2*',
		name: 'Auto2',
		component: () => import('@/views/open/Autho2.vue')
	},
	{
		path: '/mycoupons*',
		name: 'Mycoupons',
		component: () => import('@/views/moblies/coupons.vue')
	},
	{
		path: '/onecoupons',
		name: 'OneCoupons',
		component: () => import('@/views/moblies/couponsinfo.vue')
	},
	{
		path: '/onetimecard',
		name: 'Onetimecards',
		component: () => import('@/views/moblies/timecouponsinfo.vue')
	},
	{
		path: '/qrcode',
		name: 'Qrcode',
		component: () => import('@/views/open/Qrcode.vue')
	},
	{
		path: '/success',
		name: 'Success',
		component: () => import('@/views/moblies/success.vue')
	},
	{
		path: '/pushcard/:type/:pushid',
		name: 'Pushcard',
		component: () => import('@/views/open/Pushcard.vue')
	},
	{
		path: '/tipsinfo',
		name: 'Tipsinfo',
		component: () => import('@/views/open/Tipsinfo.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	routes: routes
})


router.beforeEach((to, from, next) => {

	let hasRoute = store.state.menus.hasRoutes

	let token = localStorage.getItem("shangytoken")

	if (to.path == '/login') {
		next()
	}
	else if (to.path.includes('/opencard')) {
		next()
	}
	else if (to.path.includes('/autho2')) {
		next()
	} else if (to.path.includes("/mycoupons")) {
		//如果是进入我的卡券 要放行
		next()
	} else if (to.path.includes("/onecoupons")) {
		//单个卡券查看详情 要放行
		next()
	}else if (to.path.includes("/onetimecard")) {
		//单个卡券查看详情 要放行
		next()
	}else if(to.path=='/qrcode'){
		//点击继续洗车 放行
		next()
	}else if(to.path=='/success'){
		//开卡成功放行
		next()
	}else if(to.path=='/tipsinfo'){
		//关注提示放行
		next()
	}else if(to.path.includes('/pushcard')){
		next()
	}else if (!token) {
		next({ path: '/login' })
	}
	else if (token && !hasRoute) {
		userApi.getMenus().then(res => {
			console.log(res)
			// 拿到menuList
			store.commit("setMenuList", res.data.data.result.nav)

			// 拿到用户权限
			store.commit("setPermList", res.data.data.result.authoritys)

			// 动态绑定路由
			let newRoutes = router.options.routes

			res.data.data.result.nav.forEach(menu => {
				if (menu.children) {
					menu.children.forEach(e => {

						// 转成路由
						let route = menuToRoute(e)

						// 吧路由添加到路由管理中
						if (route) {
							newRoutes[0].children.push(route)
						}

					})
				}
			})

			router.addRoutes(newRoutes)

			hasRoute = true
			store.commit("changeRouteStatus", hasRoute)
		})
	}
	next()
})


// 导航转成路由
const menuToRoute = (menu) => {
	if (!menu.component) {
		return null
	}
	let route = {
		name: menu.name,
		path: menu.path,
		meta: {
			icon: menu.icon,
			title: menu.title
		}
	}
	route.component = () => import('@/views/' + menu.component + '.vue')

	return route
}

export default router