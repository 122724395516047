import Vue from "vue"
import fn from '../config/index'
Vue.prototype.fn = fn;
Vue.mixin({
	methods: {
		hasAuth(perm) {
			var authority = this.$store.state.menus.permList

			return authority.indexOf(perm) > -1
		}
	}
})