import Vue from 'vue'
import App from './App.vue'
// 引入路由器
import router from './router'
import store from './store'
import global from './utils/globalFun'
import * as echarts from "echarts"
// 引入ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import {BmlMarkerClusterer} from 'vue-baidu-map'
import BaiduMap from 'vue-baidu-map'
import VueBarcode from "vue-barcode";
import VueQr from 'vue-qr'
import "./icons"
import wx from 'weixin-jsapi'
import dataV from '@jiaminghi/data-view'

// 炫酷的粒子动效
import  VueParticles  from   'vue-particles'
//require('./mock/mock')

Vue.config.productionTip = false
Vue.prototype.wx = wx
Vue.prototype.$echarts=echarts
Vue.component('bml-marker-cluster', BmlMarkerClusterer)
Vue.component('barcode', VueBarcode);
Vue.component('qrcode', VueQr);
Vue.use(VueParticles)
Vue.use(ElementUI)
Vue.use(dataV)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '17FXVrWmYrKURsEvCSNfoBt3Yeyiy0Nb'
})

