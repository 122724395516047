<template>
  <div class="content">
    <div class="tables">
      <h2>基本资料</h2>
      <el-descriptions class="margin-top" :column="2" size="medium" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            用户名
          </template>
          {{ userInfo.username }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            手机号
          </template>
          {{ userInfo.SJphone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            姓名
          </template>
          {{ userInfo.user }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            微信号
          </template>
          {{ userInfo.wxcode }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            性别
          </template>
          {{ userInfo.sex == 0 ? "男" : "女" }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            所属商户
          </template>
          {{ userInfo.shangj }}
        </el-descriptions-item>
      </el-descriptions>
    </div>

    <div class="resetForm">
      <h2>密码修改</h2>
      <el-form
        :model="passForm"
        status-icon
        :rules="rules"
        ref="passForm"
        label-width="100px"
      >
        <el-form-item label="旧密码" prop="currentPass">
          <el-input
            type="password"
            v-model="passForm.currentPass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input
            type="password"
            v-model="passForm.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input
            type="password"
            v-model="passForm.checkPass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item> </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('passForm')">提交</el-button>
          <el-button @click="resetForm('passForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import api from "../api/user";
export default {
  name: "usercenter",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      userInfo: {},
      passForm: {
        password: "",
        checkPass: "",
        currentPass: "",
      },
      rules: {
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { min: 6, max: 12, message: "长度在 6 到 12 个字符", trigger: "blur" },
        ],
        checkPass: [{ required: true, validator: validatePass, trigger: "blur" }],
        currentPass: [{ required: true, message: "请输入当前密码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      api.userinfo().then((res) => {
        console.log(res);
        this.userInfo = res.data.data.result;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this;
          api.updatePass(this.passForm).then((res) => {
            console.log(res);
            _this.$alert(res.data.message, "提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                this.$refs[formName].resetFields();
              },
            });
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  .tables {
    width: 70%;
  }
  .resetForm {
    margin-top: 50px;
    width: 30%;
  }
}
.el-form {
  width: 100%;
  margin-left: -50px;
}
</style>
