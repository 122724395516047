import { postRequest, getRequest, deleteRequest } from '../utils/request'
export default {
    /**
     * 获取门店列表
     * @param {} params 
     * @returns 
     */
    getStoreList(params) {
        return getRequest(`/sy/store/getStoreList`, params)
    },
    getAllArea() {
        return getRequest('/sy/store/getAllArea')
    },
    changeStoreInfo(status, params) {
        return postRequest(`/sy/store/${status}`, params)
    },
    /**
     * 获取单个门店信息
     * @param {*} id 
     * @returns 
     */
    getInfoById(id) {//根据id查询详情
        return getRequest(`/sy/store/info/${id}`)
    },
    /**
     * 删除门店
     * @param {*} id 
     * @returns 
     */
    deleteStoreById(id) {
        return deleteRequest(`/sy/store/delete/${id}`)
    },
    /***************************************************以下是分组接口调用***************************************/
    getStoreGroupList(params) {
        return getRequest(`/sy/storegroup/getStoreGroupList`, params)
    },
    getAllStoreInfo(params) {
        const { params: para } = params
        return getRequest(`/sy/storegroup/getAllStoreInfo`, para)
    },
    changeStoreGroupInfo(status, params) {
        return postRequest(`/sy/storegroup/${status}`, params)
    },
    getGroupInfoById(id) {//根据id查询详情
        return getRequest(`/sy/storegroup/info/${id}`)
    },
    deleteStoreGroupById(sgId) {
        return deleteRequest(`/sy/storegroup/delete/${sgId}`);
    },
    getQrcodeUrl(params) {
        console.log(params)
        return getRequest(`/api/vipuser/getQrcodeUrl`, params)
    },
    selectById(soeId,name){
        return getRequest(`/sy/store/UserList?soeId=${soeId}&name=${name}`)
    },
     /***************************************************首页选项调用***************************************/
    listStores(){
        return getRequest(`/sy/store/getStoresInfos`)
    }
}