<template>
  <el-container class="container">
    <el-header>
      <AppHeader />
    </el-header>
    <el-container>
      <el-aside width="225px">
        <SideMenu />
      </el-aside>
      <el-main class="main">
        <Tabs></Tabs>
        <div style="margin: 0 15px">
          <router-view />
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import SideMenu from "../components/Menu";
import AppHeader from "../components/AppHeader";
import Tabs from "../components/Tabs";

export default {
  name: "Home",
  data() {
    return {
      closable: true,
      activeName: "first",
      fits: ["fill", "contain", "cover", "none", "scale-down"],
    };
  },
  components: {
    SideMenu,
    AppHeader,
    Tabs,
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  padding: 0;
  margin: 0;
}
.el-header {
  background-color: rgba(255, 255, 255, 1);
  color: #333;
  line-height: 50px;
  height: 50px !important;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
  padding: 0;
  margin: 0;
}

.el-aside {
  border-top: 1px solid grey !important;
  background-color: #d3dce6;
  color: #333;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  line-height: 160px;
  text-align: left;
}
.main {
  background-color: #ffffff;
  padding: 0;
}
.el-main {
  line-height: 0px;
}
a {
  text-decoration: none;
}
</style>
